<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">订单信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
          </div>
        </div>
        <ul class="listBox">
          <li><span>商户订单号</span><i class="hidden1">{{detail.mchOrderNo?detail.mchOrderNo:''}}</i></li>
          <li><span>交易流水号</span><i class="hidden1">{{detail.plfOrderNo?detail.plfOrderNo:''}}</i></li>
          <li><span>三方订单号</span><i class="hidden1">{{detail.upsOrderNo?detail.upsOrderNo:''}}</i></li>
          <li><span>三方流水号</span><i class="hidden1">{{detail.thdOrderNo?detail.thdOrderNo:''}}</i></li>
          <!-- <li><span>分账流水号</span><i class="hidden1">{{detail.guaranteeTrxNo?detail.guaranteeTrxNo:''}}</i></li> -->
          <!-- <li style="height: 19px;"></li> -->
          <li><span>担保订单号</span><i class="hidden1">{{detail.mchGuaranteeNo?detail.mchGuaranteeNo:''}}</i></li>
          <li><span>担保流水号</span><i class="hidden1">{{detail.guaranteeTrxNo?detail.guaranteeTrxNo:''}}</i></li>
          <li><span>分账商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.allocMchName" placement="top">
              <b>{{detail.allocMchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>分账商户编号</span><i class="hidden1">{{detail.allocMchId?detail.allocMchId:''}}</i></li>
          <li><span>所属商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
              <b>{{detail.mchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>所属商户编号</span><i class="hidden1">{{detail.mchId?detail.mchId:''}}</i></li>
          <li><span>所属机构</span><i class="hidden1">{{detail.orgName?detail.orgName:''}}</i></li>
          <li><span>渠道商户号</span><i class="hidden1">{{detail.channelMchNo?detail.channelMchNo:''}}</i></li>
          <li><span>创建时间</span><i class="hidden1">{{detail.createTime?detail.createTime:''}}</i></li>
          <li><span>担保时间</span><i class="hidden1">{{detail.tradeTime?detail.tradeTime:''}}</i></li>
					<li><span>担保请求信息</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.requestInfo" placement="top">
              <b>{{detail.requestInfo}}</b>
            </el-tooltip>
          </i></li>
          <li><span>担保结果信息</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.responseInfo" placement="top">
              <b>{{detail.responseInfo}}</b>
            </el-tooltip>
          </i></li>
          <li><span>担保状态</span><i class="hidden1">{{guaranteeStatus[detail.guaranteeStatus]}}</i></li>
          <li><span>通知地址</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.guaranteeNotifyUrl" placement="top">
              <b>{{detail.guaranteeNotifyUrl}}</b>
            </el-tooltip>
          </i></li>
          <li><span>业务响应码</span><i class="hidden1">{{detail.resultCode?detail.resultCode:''}}</i></li>
          <li><span>业务响应描述</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.resultMessage" placement="top">
              <b>{{detail.resultMessage}}</b>
            </el-tooltip>
          </i></li>
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">担保通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
						<td>
						  <p>状态码</p>
						</td>
						<td>
						  <p>返回信息</p>
						</td>
          </tr>
          <tr v-for="(v,i) in notices" :key="i">
            <td>
              <p>{{v.notifyTime}}</p>
            </td>
            <td>
              <p>{{notifyType[v.notifyType]}}</p>
            </td>
            <td>
              <p>{{v.reachStatus?'已到达':'未响应'}}</p>
            </td>
            <td>
              <p>{{notifyStatus[v.notifyStatus]}}</p>
            </td>
						<td>
						  <p>{{v.resultCode}}</p>
						</td>
						<td>
						  <p>{{v.resultMessage}}</p>
						</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
	import {
		getGuaranteeordersDetail
	} from '@/api/trading/subaccount.js'
  export default {
    data() {
      return {
				detail:'',
				notices:[],
				guaranteeOrders:[],
        crumbs: "交易详情",
				notifyType:{
					PLATFORM:'平台',
					THIRD:'第三方'
				},
				notifyStatus:{
					SUCCESS:'成功',
					FAIL:'失败'
				},
				guaranteeStatus:{
					null: ' ',
					PROCESSING: '处理中',
					FAIL: '担保失败',
					SUCCESS: '担保成功'
				}
      }
    },
		created() {
			this.getDetail()
		},
    methods: {
			getDetail(){
				getGuaranteeordersDetail(this.$route.query.id).then(res=>{
					if(res){
						this.detail = res.resultData.detail
						this.notices = res.resultData.notices
						this.guaranteeOrders = res.resultData.guaranteeOrders
					}
				})
			},
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
    }
  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
